import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
// import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

import Button from "react-bootstrap/Button";
import Media from "../common/media";

function indication() {
  "Huh niets te doen????";
}

class SiteMediaTable extends Component {
  state = {
    //    siteMedias: this.props.siteMedias,
    handleSMTableClick: this.props.handleSMTableClick,
    handleSMTableDeleteClick: this.props.handleSMTableDeleteClick,
  };
  render() {
    const columns = [
      {
        dataField: "name",
        text: "Naam",
        filter: textFilter(),
      },
      {
        dataField: "type",
        text: "Type",
      },
      {
        dataField: "url",
        text: "Url",
      },
      {
        text: "Image",
        formatter: (cell, media, rowIndex) => {
          return (
            <div style={{ width: "400px" }}>
              <Media media={media} roundImage={false}></Media>
            </div>
          );
        },
      },
      {
        formatter: (cell, row, rowIndex) => {
          return (
            <div>
              <Button
                onClick={() => this.state.handleSMTableClick(row, rowIndex)}
              >
                Selecteer
              </Button>

              <Button
                onClick={() =>
                  this.state.handleSMTableDeleteClick(row, rowIndex)
                }
              >
                Verwijder
              </Button>
            </div>
          );
        },
      },
    ];
    /*    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //      onDoubleClick: (e, row, rowIndex) => {
        if (this.state.handleSMTableClick !== undefined) {
          this.state.handleSMTableClick(row._id, row);
          //        } else {
          //          this.setState({ redirect: `/organization/${row._id}` });
        }
      },
    };
*/
    return (
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={this.props.siteMedias}
        columns={columns}
        striped
        hover
        condensed
        noDataIndication={indication}
        pagination={paginationFactory()}
        filter={filterFactory()}
        //        rowEvents={rowEvents}
      ></BootstrapTable>
    );
  }
}
/*
        cellEdit={cellEditFactory({ mode: "click", blurToSave: true })} 
*/
export default SiteMediaTable;
