import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Row, Col, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import FormTextInput from "./common/formTextInput";
import FormTextArea from "./common/formTextArea";
import FormSelectList from "./common/formSelectList";

const ContactForm = ({ contact, doSubmit }) => {
  const [modalShow, setModalShow] = React.useState(false);

  const labelclass = `("col-lg-4 col-md-4 col-sm-4 col-xs-12")`;
  const inputclass = `("col-lg-8 col-md-8 col-sm-8 ")`;

  return (
    <>
      <ModalBedankt show={modalShow} onHide={() => setModalShow(false)} />

      <Formik
        enableReinitialize
        initialValues={contact}
        validationSchema={Yup.object({
          name: Yup.string().required("Vereist"),
          email: Yup.string()
            .email("Geen valide email adres")
            .required("Vereist"),
          //value: Yup.string().required("Vereist"),
          //            typename: Yup.number().min(1).required("Kies"),
        })}
        onSubmit={async (values) => {
          doSubmit(values);
          setModalShow(true);
        }}
      >
        {({ setFieldValue, values }) => (
          <div className="container">
            <Form>
              <h3>Persoonsgegevens</h3>

              <div className="row">
                <div className={labelclass}>Naam *</div>
                <div className={inputclass}>
                  <div className="form-group row">
                    <FormTextInput label="" name="name" type="text" />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className={labelclass}>Email *</div>
                <div className={inputclass}>
                  <div className="form-group row">
                    <FormTextInput label="" name="email" type="text" />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className={labelclass}>Telefoonnummer *</div>
                <div className={inputclass}>
                  <div className="form-group row">
                    <FormTextInput label="" name="phonenr" type="text" />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className={labelclass}>Gewenst contact</div>
                <div className={inputclass}>
                  <div id="my-radio-group"></div>
                  <div role="group" aria-labelledby="my-radio-group">
                    <label>
                      <Field type="radio" name="method" value="mail" />
                      &nbsp;&nbsp;Mail
                    </label>
                    <br />
                    <label>
                      <Field type="radio" name="method" value="phone" />
                      &nbsp;&nbsp;Telefoon
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className={labelclass}>Per telefoon bereikbaar op</div>
                <div className={inputclass}>
                  <div className="form-group row">
                    <FormTextInput label="" name="phoneable" type="text" />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <h3>Vraag</h3>
              <br />
              <div className="row">
                <div className={labelclass}>Betreft</div>
                <div className={inputclass}>
                  <div className="form-group row">
                    <FormSelectList
                      label=""
                      list={{
                        options: [
                          {
                            value: "kies...",
                            name: "geen",
                          },
                          {
                            value: "Ontmoetingen Online",
                            name: "Ontmoetingen Online",
                          },
                          {
                            value: "Muzikale Ontmoetingen",
                            name: "Muzikale Ontmoetingen",
                          },
                          {
                            value: "HuidHonger",
                            name: "HuidHonger",
                          },
                        ],
                      }}
                      name="subject"
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="row">
                <div className={labelclass}>Vraag</div>
                <div className={inputclass}>
                  <div className="form-group row">
                    <FormTextArea
                      label=""
                      name="question"
                      type="text"
                      rows={10}
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div className="row">
                <div className={labelclass}></div>
                <div className={inputclass}>
                  <div className="form-group row">
                    <button type="submit">Verstuur</button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

function ModalBedankt(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Bedankt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Bedankt</h4>
        <p>
          Uw bericht is verstuurd. Wij doen onze uiterste best zo spoedig
          mogelijk te reageren.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Sluiten</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ContactForm;
