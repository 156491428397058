import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import parse from "html-react-parser";

import {
  MdDeleteOutline,
  MdArrowUpward,
  MdArrowDownward,
  MdArrowBack,
  MdArrowForward,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import ColorPicker from "../common/colorPicker";
import Media from "./../common/media";

class PageListItemEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitePageObjectId: null,
      sitePageObject: {},
      onChangeSitePageObjects: this.props.onChangeSitePageObjects,
      backgroundColor: this.props.backgroundColor,
    };
  }

  componentDidMount() {
    if (this.props.sitePageObjectId) {
      const sitePageObject = this.props.sitePageObject;
      this.setState({ sitePageObject });
      this.setState({ sitePageObjectId: this.props.sitePageObjectId });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sitePageObjectId == null ||
      prevProps.sitePageObjectId !== this.props.sitePageObjectId ||
      prevProps.sitePageObject !== this.props.sitePageObject ||
      prevProps.backgroundColor !== this.props.backgroundColor
    ) {
      const sitePageObject = this.props.sitePageObject;
      this.setState({ sitePageObject });
      this.setState({ sitePageObjectId: this.props.sitePageObjectId });
      this.setState({ backgroundColor: this.props.backgroundColor });

      // await this.populateContactPerson();
    }
  }

  render() {
    const sitePageObject = this.state.sitePageObject;
    const sitePageObjectId = this.state.sitePageObjectId;
    const onChangeSitePageObjects = this.state.onChangeSitePageObjects;

    if (!sitePageObject || !sitePageObject._id) {
      return <div />;
    }

    const media = sitePageObject.siteMedias[0];
    const roundImage =
      sitePageObject.roundImage && sitePageObject.roundImage == true
        ? true
        : false;
    // ? sitePageObject.siteMedias[0]
    // : sitePageObject.siteMedias; // null
    return (
      <Container
        style={{
          backgroundColor: this.state.backgroundColor
            ? this.state.backgroundColor
            : "white",
        }}
      >
        <Row className="pageListBottomSpace">
          <h2>{sitePageObject.title}</h2>
          {this.props.imagePos === "left" && media && (
            <Col sm={this.props.imageSize}>
              <Media
                media={media}
                className="pageTopImage"
                roundImage={roundImage}
              />
            </Col>
          )}
          <Col sm={11 - this.props.imageSize}>{parse(sitePageObject.text)}</Col>
          {this.props.imagePos === "right" && media && (
            <Col sm={this.props.imageSize}>
              <Media
                media={media}
                className="pageTopImage"
                roundImage={roundImage}
              />
            </Col>
          )}
          <Col sm={1}>
            <MdArrowUpward
              onClick={() =>
                onChangeSitePageObjects("moveUp", sitePageObjectId)
              }
              fill="blue"
              size="30"
            />
            <MdArrowDownward
              onClick={() =>
                onChangeSitePageObjects("moveDown", sitePageObjectId)
              }
              fill="blue"
              size="30"
            />
            <MdArrowBack
              onClick={() =>
                onChangeSitePageObjects("imgLeft", sitePageObjectId)
              }
              fill="blue"
              size="30"
            />
            <MdArrowForward
              onClick={() =>
                onChangeSitePageObjects("imgRight", sitePageObjectId)
              }
              fill="blue"
              size="30"
            />
            <MdKeyboardArrowDown
              onClick={() =>
                onChangeSitePageObjects("imgSmaller", sitePageObjectId)
              }
              fill="blue"
              size="30"
            />
            <MdKeyboardArrowUp
              onClick={() =>
                onChangeSitePageObjects("imgWider", sitePageObjectId)
              }
              fill="blue"
              size="30"
            />
            <MdDeleteOutline
              onClick={() =>
                onChangeSitePageObjects("remove", sitePageObjectId)
              }
              fill="blue"
              size="30"
            />
            <ColorPicker
              onChange={onChangeSitePageObjects}
              id={sitePageObjectId}
              identifier="backgroundColor"
              colorHex={
                sitePageObject.backgroundcolor
                  ? sitePageObject.backgroundcolor
                  : "#999999"
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PageListItemEdit;
