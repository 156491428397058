import http from "./httpService";

const apiEndpoint = "/contacts";

function contactUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function emptyContact() {
  const contact = {
    name: "",
    email: "",
    phonenr: "",
    method: "",
    phoneable: "",
    subject: "",
    question: "",
  };
  return contact;
}

/*
export function getSitePageObjects(fields) {
  if (fields) {
    return http.get(apiEndpoint + "?fields=" + fields);
  } else return http.get(apiEndpoint);
}

export function getSitePageObject(sitePageObjectId) {
  return http.get(sitePageObjectUrl(sitePageObjectId));
}
*/
export function saveContact(contact) {
  if (contact._id && contact._id !== "new") {
    const body = { ...contact };
    delete body._id;
    return http.put(contactUrl(contact._id), body);
  }

  return http.post(apiEndpoint, contact);
}
/*
export function deleteSitePageObject(sitePageObjectId) {
  return http.delete(sitePageObjectUrl(sitePageObjectId));
}
*/
