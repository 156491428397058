import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";

import Media from "../common/media";
import Button from "react-bootstrap/Button";
import parse from "html-react-parser";

function indication() {
  "Huh niets te doen????";
}

/*  Parameters
                  sitePageDefinition={this.state.sitePageDefinition}
                  filterOnSitePageDefinition={true}
                  buttonText={"Verwijder"}
                  handleButtonClick={this.onSPOTableDelete}

*/

class SitePageObjectsTable extends React.Component {
  state = {
    sitePageDefinitionId: null,
    sitePageObjects: this.props.sitePageObjects,
    handleSPOTableClick: this.props.handleSPOTableClick,
    onButtonClick: this.props.handleButtonClick,
    filterOnSitePageDefinition: this.props.filterOnSitePageDefinition,
    buttonText: this.props.buttonText,
  };
  /*
  async componentDidMount(prevProps) {
    var sitePageObjects = [];
    if (this.state.filterOnSitePageDefinition) {
      if (
        this.props.sitePageDefinition &&
        (!prevProps ||
          !prevProps.sitePageDefinition ||
          this.props.sitePageDefinition._id != prevProps.sitePageDefinition._id)
      ) {
        this.props.sitePageDefinition.listObjects.map((o) => {
          //          const { data: sitePageObject } = await getSiteListObject(o._id);
          //          sitePageObjects.push(sitePageObject);
        });
        this.setState({ sitePageObjects });
      }
    } else {
      //      const { data: sitePageObjects } = await getSiteListObjects();
      this.setState({ sitePageObjects });
    }
  }

  async componentDidUpdate(prevProps) {
    const sitePageObjects = [];
    if (
      this.props.sitePageDefinition &&
      (!prevProps ||
        !prevProps.sitePageDefinition ||
        this.props.sitePageDefinition._id != prevProps.sitePageDefinition._id)
    ) {
      this.props.sitePageDefinition.listObjects.map((o) => {
        //        sitePageObjects.push(await getSiteListObject(o._id))
        //const { data: sitePageObject } = await getSiteListObject(o._id);
        //sitePageObjects.push(sitePageObject);
      });
      this.setState({ sitePageObjects });
    }
  }

  deleteRow(rowIndex) {
    const sitePageObjects = this.state.sitePageObjects;
    sitePageObjects.splice(rowIndex, 1);
    this.setState({ sitePageObjects });
    return true;
  }
*/
  render() {
    const columns = [
      {
        dataField: "language",
        text: "Taal",
        filter: textFilter(),
      },
      {
        dataField: "name",
        text: "Naam",
        filter: textFilter(),
      },
      {
        dataField: "image",
        text: "Image",
        formatter: (cell, row) => {
          if (!row.siteMedias) {
            return <div />;
          }
          const media = row.siteMedias[0];
          // console.log("media ", media);
          if (media && !media.type) {
            media.type = "image";
            // console.log("media modified", media);
          } else if (!media) {
            return <div />;
          }
          return (
            <React.Fragment>
              {media && (
                <div>
                  <Media
                    media={media}
                    //                 src={`${process.env.REACT_APP_SRV_URL}/${row.siteMedia.path}`}
                    roundImage={row.roundImage}
                    className="tableImage"
                  />
                  {row.roundImage}
                </div>
              )}
            </React.Fragment>
          );
        },
      },
      {
        dataField: "title",
        text: "Title",
      },
      {
        dataField: "text",
        text: "Text",
        formatter: (cell, row, rowIndex) => {
          return <div>{parse(row.text)}</div>;
        },
      },
      {
        formatter: (cell, row, rowIndex) => {
          if (this.props.buttonText) {
            return (
              <Button
                onClick={() => this.props.handleButtonClick(row, rowIndex)}
              >
                {this.state.buttonText}
              </Button>
            );
          }
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //      onDoubleClick: (e, row, rowIndex) => {
        if (this.state.handleSPOTableClick !== undefined) {
          this.state.handleSPOTableClick(row._id, row);
          //        } else {
          //          this.setState({ redirect: `/organization/${row._id}` });
        }
      },
    };

    return (
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={this.props.sitePageObjects}
        columns={columns}
        striped
        hover
        condensed
        noDataIndication={indication}
        pagination={paginationFactory()}
        filter={filterFactory()}
        rowEvents={rowEvents}
      ></BootstrapTable>
    );
  }
}

export default SitePageObjectsTable;
