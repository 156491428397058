import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";

// import { getSitePageDefinitions } from "../../services/sitePageDefinitionService";

function indication() {
  "Huh niets te doen????";
}

class SitePagesDefinitionTable extends React.Component {
  state = {
    sitePageDefinitions: [],
    handleSPDTableClick: this.props.handleSPDTableClick,
    redirect: "",
  };

  componentDidMount() {
    //    const sitePageDefinitions = getSitePageDefinitions();
    const sitePageDefinitions = this.props.sitePageDefinitions;
    this.setState({ sitePageDefinitions });
    //  console.log(sitePageDefinitions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.sitePageDefinitions !== prevProps.sitePageDefinitions) {
      const sitePageDefinitions = this.props.sitePageDefinitions;
      this.setState({ sitePageDefinitions });
    }
  }

  render() {
    const columns = [
      {
        dataField: "language",
        text: "Taal",
        filter: textFilter(),
      },
      {
        dataField: "name",
        text: "Naam",
        filter: textFilter(),
      },
      {
        dataField: "title",
        text: "Title",
        sort: true,
      },
      {
        dataField: "remark",
        text: "Opmerking",
        sort: true,
      },
    ];
    const defaultSorted = [
      {
        dataField: "name",
        order: "desc",
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //      onDoubleClick: (e, row, rowIndex) => {
        if (this.state.handleSPDTableClick !== undefined) {
          this.state.handleSPDTableClick(row._id, row);
          //        } else {
          //          this.setState({ redirect: `/organization/${row._id}` });
        }
      },
    };

    return (
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={this.state.sitePageDefinitions}
        columns={columns}
        striped
        hover
        condensed
        noDataIndication={indication}
        pagination={paginationFactory()}
        filter={filterFactory()}
        defaultSorted={defaultSorted}
        rowEvents={rowEvents}
      ></BootstrapTable>
    );
  }
}

export default SitePagesDefinitionTable;

/*

import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  //  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";

// import { getOrganizations } from "./../../fakeServices/fakeOrganizationService";
import { getOrganizations } from "../../services/organizationService";
import { getSitePageDefinition } from './../../fakeServices/fakeSitePageDefinitionService';

function indication() {
  "Huh niets te doen????";
}

class OrganizationsTable extends Component {
  state = {
    organizationId: this.props.organizationId,
    organizations: [],
    handleOrgTableClick: this.props.handleOrgTableClick,
    redirect: "",
  };

  async componentDidMount() {
    const { data: organizations } = await getOrganizations();
    this.setState({ organizations });
    console.log(organizations);
  }

  render() {
    const columns = [
      {
        dataField: "name",
        text: "Naam",
        filter: textFilter(),
      },
      {
        dataField: "type.name",
        text: "Type",
        sort: true,
      },
      {
        dataField: "relation.name",
        text: "Relatie",
        sort: true,
      },
      {
        dataField: "phone",
        text: "Telefoonnr",
        sort: true,
      },
      {
        dataField: "city",
        text: "Plaats",
        sort: true,
      },
      {
        dataField: "partoff.name",
        text: "Onderdeel van",
        sort: true,
      },
      {
        dataField: "website",
        text: "Website",
        formatter: (cell) => (
          <a href={cell} target="_blank" rel="noreferrer">
            {cell}
          </a>
        ),
        sort: true,
      },
    ];
    const defaultSorted = [
      {
        dataField: "name",
        order: "desc",
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        //      onDoubleClick: (e, row, rowIndex) => {
        if (this.state.handleOrgTableClick !== undefined) {
          this.state.handleOrgTableClick(row._id);
        } else {
          this.setState({ redirect: `/organization/${row._id}` });
        }
      },
    };

    if (this.state.redirect !== "") {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <BootstrapTable
        bootstrap4
        keyField="_id"
        data={this.state.organizations}
        columns={columns}
        striped
        hover
        condensed
        noDataIndication={indication}
        pagination={paginationFactory()}
        filter={filterFactory()}
        defaultSorted={defaultSorted}
        rowEvents={rowEvents}
      ></BootstrapTable>
    );
  }
}


*/
