import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
// import { Link } from "react-router-dom";

class NavBar2 extends Component {
  state = {
    dropdownOpen: false,
    dropdownMenu: "",
    user: this.props.user,
    who: this.props.who,
    projects: this.props.projects,
  };

  /*  

  setDropdownMenu = (name) => {
    this.setState({ dropdownMenu: name });
  };

  toggleOpen = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
*/

  componentDidUpdate(prevProps) {
    if (this.props.user && prevProps.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }
    if (this.props.who && prevProps.who !== this.props.who) {
      this.setState({ who: this.props.who });
    }
    if (this.props.projects && prevProps.projects !== this.props.projects) {
      this.setState({ projects: this.props.projects });
    }
  }

  render() {
    const { user } = this.state;
    const { who } = this.state;
    const { projects } = this.state;

    /* <nav className="navbar navbar-expand-lg navbar-light bg-light">  */

    return (
      <Container className="justify-content-md-right" bg="#F1EFE4">
        <Navbar
          collapseOnSelect
          expand="sm"
          bg="#21EFE4"
          className="justify-content-end"
          fixedt="top"
        >
          <Container>
            <Navbar.Brand href="#home">
              <Image
                src={`${process.env.REACT_APP_SRV_URL}/sitemedia/logo-anna.png`}
                className="tableImage centerImage"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="navbar-collapse"
              className="justify-content-end"
            >
              <Nav className="mr-auto">
                <Nav.Link href="home">Home</Nav.Link>
                <NavDropdown title="Over ons" id="wie-nav-dropdown">
                  <NavDropdown.Item href="anna">Anna</NavDropdown.Item>
                  <NavDropdown.Item href="wie">Team</NavDropdown.Item>
                  <NavDropdown.Item href="contact">Contact</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Projecten" id="basic-nav-dropdown">
                  {projects &&
                    projects.length &&
                    projects.map((project) => (
                      <NavDropdown.Item href={project.href} key={project.nr}>
                        {project.menuname}
                      </NavDropdown.Item>
                    ))}
                  {/* <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item> */}
                </NavDropdown>
                <Nav.Link href="nieuws">Nieuws</Nav.Link>
                <NavDropdown title="Inschrijven" id="wie-nav-dropdown">
                  <NavDropdown.Item href="sessie">
                    Als sessiedeelnemer
                  </NavDropdown.Item>
                  <NavDropdown.Item href="vrijwiliger">
                    Als ( vrijwillige ) sessieleider
                  </NavDropdown.Item>
                </NavDropdown>

                {/*who && <Nav.Link href={who.href}>{who.menuname}</Nav.Link>*/}
                <NavDropdown
                  title="Ondersteuning"
                  id="ondersteuning-nav-dropdown"
                >
                  <NavDropdown.Item href="ondersteuning">
                    Fondsen
                  </NavDropdown.Item>
                  <NavDropdown.Item href="donaties">Donaties</NavDropdown.Item>
                  <NavDropdown.Item href="overig">Overig</NavDropdown.Item>
                </NavDropdown>

                {/*}            <Nav.Link href="steunons">Steun ons</Nav.Link> 
                <Nav.Link href="contact">Contact</Nav.Link>*/}
                <NavDropdown
                  title={!user ? "Login" : user.name}
                  id="login-nav-dropdown"
                >
                  {!user && (
                    <React.Fragment>
                      <NavDropdown.Item href="/login">Login</NavDropdown.Item>
                      <NavDropdown.Item href="/register">
                        Registreren
                      </NavDropdown.Item>
                    </React.Fragment>
                  )}
                  {user && user.userType && user.userType.isSiteAdmin && (
                    <NavDropdown.Item href="/editinfosite">
                      Edit site
                    </NavDropdown.Item>
                  )}
                  {user && (
                    <React.Fragment>
                      <NavDropdown.Item href="/profile">
                        Profiel
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                    </React.Fragment>
                  )}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    );
  }
}

export default NavBar2;

/*  start


    return (
      <nav className="navbar navbar-light bg-light">
        <a className="navbar-brand" href="#">
          <Image
            src={`${process.env.REACT_APP_SRV_URL}/sitemedia/logo-anna.png`}
            className="tableImage centerImage"
            alt=""
          />
        </a>
        <h1>Welkom bij Anna Experiences</h1>
        {/* default hamburger instelling
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>   * /}

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/ * <div className="collapse navbar-collapse" id="navbarSupportedContent">  * /}
        <div className="collapse" id="navbarSupportedContent">
          {/ * <ul className="navbar-nav mr-auto">  * /}
          <ul className="navbar-nav">
            <MenuItemButton
              title="Home"
              to="/"
              menuName="home"
              onMouseEnter={this.setDropdownMenu}
            />
            <li className="nav-item dropdown">
              <MenuItem
                menuName="projects"
                title="Projecten"
                onClick={this.toggleOpen}
                onMouseEnter={this.setDropdownMenu}
                dropdownOpen={dropdownOpen}
                dropdownMenu={dropdownMenu}
              >
                <SubMenuItem
                  title="Muzikale Ontmoetingen"
                  to="/musical"
                  onClick={this.toggleOpen}
                />
                <SubMenuItem
                  title="Huidhonger"
                  to="/huidhonger"
                  onClick={this.toggleOpen}
                />
                <SubMenuItem
                  title="Ontmoetingen Online"
                  to="/onlinemeetings"
                  onClick={this.toggleOpen}
                />
              </MenuItem>
            </li>
            {/ *}
            <MenuItemButton
              title="Sessies"
              to="/sessions"
              menuName="sessions"
              onMouseEnter={this.setDropdownMenu}
            /> * /}
            {user && (
              <MenuItemButton
                title="Edit Info site"
                to="/editinfosite"
                menuName="editinfosite"
                onMouseEnter={this.setDropdownMenu}
              />
            )}
            {user && (
              <li className="nav-item dropdown">
                <MenuItem
                  menuName="administration"
                  title="Beheer"
                  onClick={this.toggleOpen}
                  onMouseEnter={this.setDropdownMenu}
                  dropdownOpen={dropdownOpen}
                  dropdownMenu={dropdownMenu}
                >
                  <SubMenuItem
                    title="Organisaties"
                    to="/organizations"
                    onClick={this.toggleOpen}
                  />
                  <SubMenuItem
                    title="Klanten"
                    to="/customers"
                    onClick={this.toggleOpen}
                  />
                  <SubMenuItem
                    title="Afspraken"
                    to="/deals"
                    onClick={this.toggleOpen}
                  />
                  <SubMenuItem
                    title="Systemen"
                    to="/systems"
                    onClick={this.toggleOpen}
                  />
                </MenuItem>
              </li>
            )}
            <li className="nav-item dropdown">
              <MenuItem
                menuName="logins"
                title={!user ? "Login" : user.name}
                onClick={this.toggleOpen}
                onMouseEnter={this.setDropdownMenu}
                dropdownOpen={dropdownOpen}
                dropdownMenu={dropdownMenu}
              >
                {!user && (
                  <React.Fragment>
                    <SubMenuItem
                      title="Login"
                      to="/login"
                      menuName="login"
                      onClick={this.toggleOpen}
                      onMouseEnter={this.setDropdownMenu}
                    />
                    <SubMenuItem
                      title="Registreer"
                      to="/register"
                      menuName="register"
                      onClick={this.toggleOpen}
                      onMouseEnter={this.setDropdownMenu}
                    />
                  </React.Fragment>
                )}
                {user && (
                  <React.Fragment>
                    <SubMenuItem
                      title="Profile"
                      to="/profile"
                      menuName="profile"
                      onClick={this.toggleOpen}
                      onMouseEnter={this.setDropdownMenu}
                    />
                    <SubMenuItem
                      title="Logout"
                      to="/logout"
                      menuName="logout"
                      onClick={this.toggleOpen}
                      onMouseEnter={this.setDropdownMenu}
                    />
                  </React.Fragment>
                )}
              </MenuItem>
            </li>
            {/ *}
            <MenuItemButton
              title="Registreer Winterspecial"
              to="/registerws"
              menuName="registerws"
              onMouseEnter={this.setDropdownMenu}
            />* /}
          </ul>
        </div>
      </nav>
    );
einde */

/*

                <SubMenuItem
                  title="Nieuwe organisatie"
                  to="/organizations/new"
                  onClick={this.toggleOpen}
                />
*/

/*   oosistema link


  render() {
    const { dropdownOpen, dropdownMenu } = this.state;

    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item dropdown">
              <MenuItem
                menuName="todo"
                title="Todo"
                onClick={this.toggleOpen}
                onMouseEnter={this.setDropdownMenu}
                dropdownOpen={dropdownOpen}
                dropdownMenu={dropdownMenu}
              >
                <SubMenuItem
                  title="Todos"
                  to="/todos"
                  onClick={this.toggleOpen}
                />
                <SubMenuItem
                  title="Nieuw"
                  to="/todos/new"
                  onClick={this.toggleOpen}
                />
              </MenuItem>
            </li>
            <MenuItemButton
              title="Contacten"
              to="/contactpersons"
              menuName="contacten"
              onMouseEnter={this.setDropdownMenu}
            />
            <li className="nav-item dropdown">
              <MenuItem
                menuName="organizations"
                title="Organisaties"
                onClick={this.toggleOpen}
                onMouseEnter={this.setDropdownMenu}
                dropdownOpen={dropdownOpen}
                dropdownMenu={dropdownMenu}
              >
                <SubMenuItem
                  title="Organisaties"
                  to="/organizations"
                  onClick={this.toggleOpen}
                />
                <SubMenuItem
                  title="Nieuwe organisatie"
                  to="/organization/new"
                  onClick={this.toggleOpen}
                />
              </MenuItem>
            </li>
            <MenuItemButton
              title="Prospects"
              to="/prospects"
              menuName="prospects"
              onMouseEnter={this.setDropdownMenu}
            />
            <MenuItemButton
              title="Sessies"
              to="/sessions"
              menuName="sessions"
              onMouseEnter={this.setDropdownMenu}
            />
            <li className="nav-item dropdown">
              <MenuItem
                menuName="system"
                title="Systeem"
                onClick={this.toggleOpen}
                onMouseEnter={this.setDropdownMenu}
                dropdownOpen={dropdownOpen}
                dropdownMenu={dropdownMenu}
              >
                <SubMenuItem
                  title="Processen"
                  to="/processes"
                  onClick={this.toggleOpen}
                />

                <SubMenuItem
                  title="Gebruikers"
                  to="/users"
                  onClick={this.toggleOpen}
                />
                <SubMenuItem
                  title="Keuzelijsten"
                  to="/selectlists"
                  onClick={this.toggleOpen}
                />
              </MenuItem>
            </li>

            <MenuItemButton
              title="Login"
              to="/login"
              menuName="login"
              onMouseEnter={this.setDropdownMenu}
            />
          </ul>
        </div>
      </nav>
*/
