import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Home from "./components/home";
import Contact from "./components/contact";
import NavBar2 from "./components/navBar2";
import EditInfoSite from "./components/editInfoSite/editInfoSite";
import RegisterForm from "./components/registerForm";
import LoginForm from "./components/loginForm";
import Logout from "./components/logout";
import ProtectedRoute from "./components/common/protectedRoute";
import auth from "./services/authService";

import "bootstrap/dist/js/bootstrap.bundle";

import "./App.css";
import "./components/page/page.css";
// import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg";
import ScrollToTop from "./components/common/scrollToTop";

import { getSitePageDefinitions } from "./services/sitePageDefinitionService";

class App extends Component {
  state = {
    projects: null,
    who: null,
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
    const projects = [];
    const { data: sitePageDefinitions } = await getSitePageDefinitions();
    for (let i = 0; i < sitePageDefinitions.length; i++) {
      if (sitePageDefinitions[i].type === "us") {
        const menuname =
          sitePageDefinitions[i].projectname &&
          sitePageDefinitions[i].projectname !== ""
            ? sitePageDefinitions[i].projectname
            : "Wie zijn wij";
        const who = {
          menuname: menuname,
          href: sitePageDefinitions[i].name,
        };
        this.setState({ who });
      } else if (sitePageDefinitions[i].type === "project") {
        const menuname =
          sitePageDefinitions[i].projectname &&
          sitePageDefinitions[i].projectname !== ""
            ? sitePageDefinitions[i].projectname
            : "p";
        const project = {
          menuname: menuname,
          href: sitePageDefinitions[i].name,
          nr: parseInt(sitePageDefinitions[i].projectnr),
        };
        projects.push(project);
      }
    }
    projects.sort((firstItem, secondItem) => firstItem.nr - secondItem.nr);
    this.setState({ projects });
  }

  render() {
    const { user } = this.state;
    const who = this.state.who;
    const projects = this.state.projects;

    return (
      <React.Fragment>
        {/* <h1>{document.referrer}</h1> */}
        <NavBar2 user={user} projects={projects} who={who}></NavBar2>
        <main className="container">
          <ScrollToTop />
          <Switch>
            {/*<Route
              path="/nl/contact"
              render={(props) => {
                return <Contact {...props} user={this.state.user} />;
              }}
            />*/}
            <Route
              path="/nl/:page"
              render={(props) => {
                if (props.match.url === "/nl/contact")
                  return <Contact {...props} user={this.state.user} />;
                return <Home {...props} user={this.state.user} />;
              }}
            />
            {/* <Route path="/" exact to="/signup" component={Home} /> */}
            <ProtectedRoute path="/editinfosite" component={EditInfoSite} />
            <Route path="/register" component={RegisterForm} />
            {/*<Route path="/registerws" component={RegisterWinterSpecialForm} /> */}
            <Route path="/login" component={LoginForm} />
            <Route path="/logout" component={Logout} />
            {/* <Redirect path="/" exact to="/nl/home" />  */}
            <Redirect to="/nl/home" />
            {/*<Redirect path="/" exact to="/registerws" /> */}
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
