import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
//import axios from "axios";
//import ProgressBar from "react-bootstrap/ProgressBar";

import FormTextInput from "../common/formTextInput";
import FormSelect from "../common/formSelect";

class SiteMediaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteMedia: {},
      onSubmit: this.props.onSubmit,
      initialValues: {
        name: "",
        type: "",
        url: "",
        movieImageUrl: "",
      },
      //      selectedFiles: null,
      //      loaded: 0,
    };
  }

  populateSiteMedia() {
    //  try {
    // console.log("populate Form SiteMedia ", this.props.siteMedia);
    this.setState({ siteMedia: this.props.siteMedia });
    const initialValues = this.mapToForm(this.props.siteMedia);
    this.setState({ initialValues });
    //  } catch (ex) {
    //    console.log("Error getting ContactPerson on id ", ex);
    //    if (ex.response && ex.response.status === 404)
    //      this.props.history.replace("/not-found");
    //  }
  }

  mapToForm(siteMedia) {
    let initialValues = siteMedia;
    if (!initialValues.url) initialValues.url = "";
    if (!initialValues.movieImageUrl) initialValues.movieImageUrl = "";
    // here new default values, not in the db, can be added
    return initialValues;
  }

  mapToDb(siteMedia) {
    let siteMediadb = siteMedia;
    return siteMediadb;
  }

  componentDidMount() {
    if (this.props.siteMedia)
      // await this.populateContactPerson();
      this.populateSiteMedia();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.siteMedia._id !== this.props.siteMedia._id)
      this.populateSiteMedia();
    // await this.populateContactPerson();
  }

  doSubmit = async (values) => {
    this.state.onSubmit(values);
  };
  /*
  onChangeHandler = (event) => {
    console.log(event.target.files);
    this.setState({ selectedFiles: event.target.files, loaded: 0 });
  };

  onUploadHandler = () => {
    const data = new FormData();
    data.append("uploadtype", "sitemedia");

    for (var x = 0; x < this.state.selectedFiles.length; x++) {
      data.append("file", this.state.selectedFiles[x]);
    }

    axios
      .post(apiUrl + "/upload", data, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then((res) => {
        // then print response status
        console.log(res.statusText);
      });
  };
*/

  /*
  doSubmit = async (values) => {
    const contactPersondb = this.mapToDb(values);
    console.log("ContactPerson to save ", contactPersondb);
    console.log(
      "ContactPerson to save json ",
      JSON.stringify(contactPersondb, null, 4)
    );
    / *
    const { data: ContactPersonSaved } = await saveContactPerson(
      ContactPersondb
    );
    delete ContactPersonSaved.__v;
    this.setState({ initialValues: this.mapToForm(ContactPersonSaved) });
* /
    //    this.props.handleContactPersonsTableClick(ContactPersonSaved._id);
  };

  handleGiveOptionsFunction = (func) => {
    //    this.setState({ getOptions: func });
  };
*/
  render() {
    return (
      <>
        <Formik
          initialValues={this.state.initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            //name: Yup.string().required("Vereist"),
            //value: Yup.string().required("Vereist"),
            //            typename: Yup.number().min(1).required("Kies"),
          })}
          onSubmit={async (values) => {
            this.doSubmit(values);
          }}
        >
          <div className="container">
            <Form>
              <div className="row">
                <div className="col-6">
                  {/* <!--left side --> */}
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Naam"
                      name="name"
                      type="text"
                    />
                  </div>
                  <div className="form-group row">
                    <FormSelect label="Type" name="type">
                      <option value="">Selecteer een type</option>
                      <option value="image">Image</option>
                      <option value="movie">Film</option>
                      <option value="youtube">Youtube</option>
                    </FormSelect>
                  </div>
                  <div className="form-group row">
                    <FormTextInput label="Url" name="url" type="text" />
                  </div>
                  <div className="form-group row">
                    <FormTextInput
                      label="Movie/YT Url ( kopieer embedded url van youtube, pas eventueel titel aan)"
                      name="movieImageUrl"
                      type="text"
                    />
                  </div>

                  <button type="submit">Opslaan</button>
                </div>
              </div>
            </Form>
          </div>
        </Formik>
        {/*
        <h2>Upload files</h2>
        <p>
          Je kunt meerdere files selecteren en die worden tegelijkertijd
          geupload. Tijdens het uploaden wordt de database bijgewerkt en
          verschijnen de imagees/movies in bovenstaande lijst, waarna deze
          bijgewerkt kan worden.
        </p>
        <input
          type="file"
          class="form-control"
          multiple
          onChange={this.onChangeHandler}
        />
        <button
          type="button"
          class="btn btn-success btn-block"
          onClick={this.onUploadHandler}
        >
          Upload
        </button>
        <ProgressBar
          now={this.state.loaded}
          label={`${Math.round(this.state.loaded, 2)}%`}
        />
*/}
      </>
    );
  }
}

export default SiteMediaForm;
