import React from "react";
import { Image } from "react-bootstrap";
//import { useState, useEffect, useRef } from "react";
//import { Container } from "react-bootstrap";

//import parse from "html-react-parser";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

//TODO: er wordt nu uitgegeaan van een aspect ratio 16/9 = 56.15% zie app.cs className="video-responsive" deze moet nog gecontroleerd worden met  width/height ratio in link
export default function Media(media, roundImage) {
  const m = media.media;
  const r = media.roundImage && media.roundImage == true ? true : false;
  const text = media.mediaText;

  //const [isHovering, setIsHovering] = useState(false);

  /*
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleClick = () => {
    console.log("handleClick");
  };
*/
  if (!media) return <div />;

  //  console.log("media ", m, m.type);
  if (!m || !m.type) return <div />;
  if (m.type === "image") {
    return (
      <div>
        {r === true && (
          <React.Fragment>
            <Image
              src={`${process.env.REACT_APP_SRV_URL}/${m.path}`}
              className="pageTopImage"
              style={{ borderRadius: "50%", overflow: "hidden" }}
            />
          </React.Fragment>
        )}
        {r === false && (
          <React.Fragment>
            <Image
              src={`${process.env.REACT_APP_SRV_URL}/${m.path}`}
              className="pageTopImage"
            />
          </React.Fragment>
        )}
        {text && text !== "" && (
          <>
            {text}
            <br />
          </>
        )}
        <h6>{m.name}</h6>
      </div>
    );
  } else if (m.type === "youtube") {
    const embedcode = getYahooId(m.movieImageUrl);

    return (
      <div className="App">
        <YoutubeEmbed embedId={embedcode} />
        <h6>{m.name}</h6>
      </div>
    );
  } else {
    return <div />;
  }
}

const getYahooId = (youtubeUrl) => {
  // '<iframe width="1053" height="592" src="https://www.youtube.com/embed/t808BgOjZPo" title="Romeo Santos, SUS HUELLAS   / Marco y Sara bachata style , workshop en  ISTAMBUL  salsa congress" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  const lefts = "https://www.youtube.com/embed/";
  let url = youtubeUrl.substring(youtubeUrl.indexOf(lefts) + lefts.length);
  let embedcode = url.substring(0, url.indexOf('"'));

  return embedcode;
};

/*
const Media = (media) => {
  const listRef = useRef();

  // The size of the list
  // It will be updated later
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  // The data of the list at the beginning
  const [listItems, setListItems] = useState([
    {
      id: 0,
      title: "Item 0",
    },
    {
      id: 1,
      title: "Item 1",
    },
  ]);

  // This function is trggered when the "Add new item" button gets clicked
  const addItem = () => {
    const items = [...listItems];
    const newItem = {
      id: items.length + 1,
      title: `Item ${items.length + 1}`,
    };

    items.push(newItem);
    setListItems(items);
  };

  // This function calculates width and height of the list
  const getListSize = () => {
    const newWidth = listRef.current.clientWidth;
    setWidth(newWidth);
    console.log("width ", newWidth);

    const newHeight = listRef.current.clientHeight;
    setHeight(newHeight);
    console.log("width ", newHeight);
  };

  // Get 'width' and 'height' after the initial render and every time the list changes
  useEffect(() => {
    getListSize();
  }, [listItems]);

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    window.addEventListener("resize", getListSize);
  }, []);

  return (
    <div className="Container">
      <button className="button" onClick={addItem}>
        Add New Item
      </button>

      {width && <h3>Width: {width}px</h3>}
      {height && <h3>Height: {height}px</h3>}

      <ul className="list" ref={listRef}>
        {listItems.map((item) => (
          <li className="item" key={item.id}>
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Media;
*/
/*

class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      media: null,
    };
  }
  // (siteMediaCarousel) {

  //  handleSelect = (selectedIndex, e) => {
  //    setIndex(selectedIndex);
  //  };
  //     <Carousel activeIndex={index} onSelect={handleSelect}>

  render() {
    const m = this.props.media.m ? this.props.media.m : this.props.media;
    if (!m.type || m.type === "image") {
      return (
        <Image
          className="d-block w-100"
          stylekk={{ width: "100%" }}
          src={`${process.env.REACT_APP_SRV_URL}/${m.path}`}
        />
      );
    } else if (m.type === "youtube") {
      return (
        <div>
          {/* <iframe
            width="470"
            height="835"
            src="https://www.youtube.com/embed/nu4wBo-AnGI"
            title="Top Gun - Vik x Miranda"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
      /> * /}
          {parse(m.movieImageUrl)}
        </div>
      );
    }
  }
}

export default Media;

*/
