import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import parse from "html-react-parser";
import { Row, Col, Container } from "react-bootstrap";

import { getSitePageObject } from "../../services/sitePageObjectService";
import SimpleCarousel from "../common/simpleCarousel";
import Media from "../common/media";

class PageTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitePageObjectId: null,
      sitePageObject: {},
      imageSize: 12,
    };
    //    this.ref = React.createRef();
  }

  async componentDidMount() {
    //    if (this.ref && this.ref.current) {
    //      const { current } = this.ref;
    //      console.log(`${current.offsetWidth}, ${current.offsetHeight}`);
    //    }
    if (this.props.sitePageObjectId) {
      const { data: sitePageObject } = await getSitePageObject(
        this.props.sitePageObjectId
      );
      this.setState({ sitePageObject });
      this.setState({ imageSize: this.props.imageSize });
    }
  }

  async componentDidUpdate(prevProps) {
    //    if (this.ref && this.ref.current) {
    //      const { current } = this.ref;
    //      console.log(`${current.offsetWidth}, ${current.offsetHeight}`);
    //      this.ref.current.addEventListener("resize", this.handleResize);
    //      this.ref.current.addEventListener("load", () => {
    //        console.log(this.ref.current.clientHeight);
    //      });
    //    }

    if (
      prevProps.sitePageObjectId == null ||
      prevProps.sitePageObjectId !== this.props.sitePageObjectId
    ) {
      const { data: sitePageObject } = await getSitePageObject(
        this.props.sitePageObjectId
      );
      this.setState({ sitePageObject });
      this.setState({ imageSize: this.props.imageSize });

      // await this.populateContactPerson();
    }
  }

  //  handleResize() {
  //    if (this.ref && this.ref.current) {
  //      const { current } = this.ref;
  //      console.log(`${current.offsetWidth}, ${current.offsetHeight}`);
  //    }
  //  }

  render() {
    const sitePageObject = this.state.sitePageObject;
    const roundImage =
      sitePageObject.roundImage && sitePageObject.roundImage === true
        ? true
        : false;
    const imageSize = this.state.imageSize;
    const borderSize = (12 - imageSize) / 2;

    if (
      !sitePageObject ||
      !sitePageObject._id ||
      !sitePageObject.siteMedias ||
      sitePageObject.siteMedias.length === 0
    ) {
      return <div />;
    }
    //    this.getListSize();

    const multiple = sitePageObject.siteMedias.length > 1;

    if (multiple) {
      return (
        <>
          <Container
            //        ref={this.ref}
            style={{
              backgroundColor: this.props.backgroundColor,
              paddingTop: "62px",
              paddingLeft: "62px",
              paddingRight: "62px",
              bottommargin: "12px",
            }}
          >
            <Row>
              <Col sm={borderSize} />
              <Col sm={imageSize}>
                <SimpleCarousel
                  siteMediaCarousel={sitePageObject.siteMedias}
                  roundImage={roundImage}
                />
              </Col>
              <Col sm={borderSize} />
            </Row>
            <br />
            {parse(sitePageObject.text)}
            <br />
          </Container>{" "}
          <br />
        </>
      );
    } else {
      return (
        <>
          <Container
            //        ref={this.ref}
            style={{
              backgroundColor: this.props.backgroundColor,
              paddingTop: "62px",
              paddingLeft: "62px",
              paddingRight: "62px",
              bottommargin: "12px",
            }}
          >
            <Row>
              <Col sm={borderSize} />
              <Col sm={imageSize}>
                {!multiple && (
                  <Media
                    media={sitePageObject.siteMedias[0]}
                    roundImage={roundImage}
                  />
                )}
              </Col>
              <Col sm={borderSize} />
            </Row>
            <br />
            {parse(sitePageObject.text)}
            <br />
          </Container>
          <br />
        </>
      );
    }
  }
}

export default withRouter(PageTop);
