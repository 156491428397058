import React, { Component } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Redirect } from "react-router";
//import * as userService from "../services/userService";
import auth from "../services/authService";

import FormTextInput from "./common/formTextInput";

class LoginForm extends Component {
  state = {
    data: { email: "", password: "" },
    errors: {},
  };

  /* PM: dit is joi format, moet yup worden 
  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };
*/

  doSubmit = async (data) => {
    try {
      //     const { data } = this.state;
      await auth.login(data.email, data.password);

      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        //        errors.username = ex.response.data;
        alert("Fout: Email adres en/of wachtwoord fout");
        this.setState({ errors });
      }
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    return (
      <div>
        <h1>Login</h1>
        <Formik
          initialValues={this.state.data}
          enableReinitialize
          validationSchema={Yup.object({
            //name: Yup.string().required("Vereist"),
            //value: Yup.string().required("Vereist"),
            //            typename: Yup.number().min(1).required("Kies"),
          })}
          onSubmit={async (values) => {
            this.doSubmit(values);
          }}
        >
          <div className="container">
            <Form>
              <div className="row">
                <div className="col-6">
                  {/* <!--left side --> */}
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Email"
                      name="email"
                      type="text"
                    />
                  </div>
                  <div className="form-group row">
                    <FormTextInput
                      className="formLabel"
                      label="Wachtwoord"
                      name="password"
                      type="password"
                    />
                  </div>

                  <button type="submit">Login</button>
                </div>
              </div>
            </Form>
          </div>
        </Formik>
      </div>
    );
  }
}

export default LoginForm;
