import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import parse from "html-react-parser";

import { getSitePageObject } from "../../services/sitePageObjectService";
import Media from "../common/media";

class PageListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitePageObjectId: null,
      sitePageObject: {},
    };
  }

  async componentDidMount() {
    if (this.props.sitePageObjectId) {
      const { data: sitePageObject } = await getSitePageObject(
        this.props.sitePageObjectId
      );
      this.setState({ sitePageObject });
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.sitePageObjectId == null ||
      prevProps.sitePageObjectId !== this.props.sitePageObjectId
    ) {
      const { data: sitePageObject } = await getSitePageObject(
        this.props.sitePageObjectId
      );
      this.setState({ sitePageObject });
      // await this.populateContactPerson();
    }
  }

  render() {
    const sitePageObject = this.state.sitePageObject;
    const linkTo = "/nl/" + sitePageObject.linkUrl;

    if (!sitePageObject || !sitePageObject._id) {
      return <div />;
    }
    const media = sitePageObject.siteMedias[0];

    return (
      <React.Fragment>
        {/*<Col sm={sitePageObject.imageWidth}> */}
        <Container
          style={{
            backgroundColor: this.props.backgroundColor,
            padding: "30px",
            bottommargin: "12px",
          }}
        >
          {!this.props.fotoabove && (
            <Row className="pageListBottomSpace">
              {this.props.imagePos === "left" && (
                <Col sm={this.props.imageSize} className="bgColor">
                  <Media
                    media={media}
                    className="pageTopImage"
                    roundImage={sitePageObject.roundImage}
                  />
                </Col>
              )}
              <Col sm={12 - this.props.imageSize} className="bgColor">
                <h2>{sitePageObject.title}</h2>
                {parse(sitePageObject.text)}
                {/*sitePageObject.linkUrl && (
                  <Link to={linkTo}> ... meer ...</Link>
                ) */}
              </Col>
              {this.props.imagePos === "right" && (
                <Col sm={this.props.imageSize} className="bgColor">
                  <Media
                    media={media}
                    className="pageTopImage"
                    roundImage={sitePageObject.roundImage}
                  />
                </Col>
              )}
            </Row>
          )}
          {this.props.fotoabove && (
            <React.Fragment>
              <Media
                media={media}
                className="pageTopImage"
                roundImage={sitePageObject.roundImage}
              />
              {parse(sitePageObject.text)}
            </React.Fragment>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(PageListItem);

/*

        <Col sm={12 - this.props.imageSize}>
          <h2>{sitePageObject.title}</h2>
          <p>
            {sitePageObject.text}
            {sitePageObject.linkUrl && (
              <Link to="/nl/${sitePageObject.linkUrl}"> ... meer ...</Link>
            {/* <Button
                onClick={() =>
                  this.props.history.push(`/nl/${sitePageObject.linkUrl}`)
                }
                color="primary"
              >
                Meer
              </Button> * /}
            )}
          </p>
        </Col>


*/
