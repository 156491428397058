import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import parse from "html-react-parser";

import Media from "./media";

class SimpleCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteMediaCarousel: [],
    };
  }
  // (siteMediaCarousel) {

  //  handleSelect = (selectedIndex, e) => {
  //    setIndex(selectedIndex);
  //  };
  //     <Carousel activeIndex={index} onSelect={handleSelect}>

  render() {
    return (
      <Carousel fade indicators="false">
        {this.props.siteMediaCarousel.length &&
          this.props.siteMediaCarousel.map((media) => (
            <Carousel.Item interval={media.delay}>
              {/*<Carousel.Caption>{parse(media.text)}</Carousel.Caption>*/}
              <Media
                className="d-block w-100"
                stylekk={{ width: "100%" }}
                media={media}
                roundImage={this.props.roundImage}
                mediaText={parse(media.text)}
              />
            </Carousel.Item>
          ))}
      </Carousel>
    );
  }
}

export default SimpleCarousel;
